@import url("https://fonts.googleapis.com/css?family=Lato:400,500,600,700&display=swap");

.wrapper {
	display: inline-flex;
	/* background: #fff; */
	height: 55px;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	border-radius: 15px;
	border: 1px solid lightgrey;
	padding: 10px 0px;
	margin-top: 5px;
}
.wrapper .option {
	/* background: #fff; */
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	/* justify-content: space-between; */
	margin: 0 10px;
	border-radius: 5px;
	cursor: pointer;
	padding: 0 10px;
	border: 2px solid lightgrey;
	transition: all 0.3s ease;
}
.wrapper .option .dot {
	height: 20px;
	width: 20px;
	/* background: #d9d9d9; */
	border-radius: 50%;
	position: relative;
}
.wrapper .option .dot::before {
	position: absolute;
	content: "";
	top: 4px;
	left: 4px;
	width: 12px;
	height: 12px;
	background: #701b6b;
	border-radius: 50%;
	opacity: 0;
	transform: scale(1.5);
	transition: all 0.3s ease;
}
input[type="radio"] {
	display: none;
}
#option-1:checked:checked ~ .option-1,
#option-2:checked:checked ~ .option-2 {
	border-color: #701b6b;
	background: #701b6b;
}
#option-1:checked:checked ~ .option-1 .dot,
#option-2:checked:checked ~ .option-2 .dot {
	background: #fff;
}
#option-1:checked:checked ~ .option-1 .dot::before,
#option-2:checked:checked ~ .option-2 .dot::before {
	opacity: 1;
	transform: scale(1);
}
.wrapper .option span {
	font-size: 14px;
	color: #808080;
}
#option-1:checked:checked ~ .option-1 span,
#option-2:checked:checked ~ .option-2 span {
	color: #fff;
}
