@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
}
.font-roboto{
    font-family: "Poppins", sans-serif;
}
.card {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
  }
  
  /* On mouse-over, add a deeper shadow */
  .card-darker {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.transition-animation{
  transition: all ease 0.4s;
}
.memo-header{
  gap:16px;
  align-items: center;
  background: #ffff;
  padding:0.5em 1em;
}

.memo-main-container{
  background:#E0E0E0 ;
}
.memo-container{
  padding: 1em;
}

.text-center{
  text-align: center;
}